import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  TextField,
  Button,
  Container,
  Grid,
  InputAdornment,
  IconButton,
  Alert,
  Stack,
  CircularProgress,
  Dialog,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { baseUrl } from "../utils/constant_api";
import axios from "axios";
import { useRef } from "react";
import { FaCheck, FaClock } from "react-icons/fa";

const theme = createTheme();

const RegistrationForm = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setError] = useState("");

  const [firstName, setFirstName] = useState("");
  const [entreprise, setEntreprise] = useState("");
  const [email, setEmail] = useState("");
  const [ville, setVille] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [quatier, setQuatier] = useState("");

  // const [file, setFile] = useState([]);
  const [image, setImage] = useState([]);
  const [message, setMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [success, setSuccess] = useState(false);

  const [images, setImages] = useState([]);
  const [myFiles, setMyFiles] = useState([]);
  const fileInputRef = useRef(null);

  const onFileSelect = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    const files = Array.from(event.target.files);
    setMyFiles(files);

    if (files.length === 0) return;

    const imageFiles = [];
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.split("/")[0] !== "image") continue;
      if (!images.some((e) => e.name === files[i].name)) {
        setImages((prevImages) => [
          ...prevImages,
          {
            name: files[i].name,
            url: URL.createObjectURL(files[i]),
          },
        ]);
      }
      imageFiles.push(files[i]);
    }
    setMyFiles(imageFiles);
    console.log(images);
  };

  const goToLogin = () => {
    window.location.href = "/login";
  };

  const Close = () => {
    setIsOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("ville", ville);
    formData.append("entreprise", entreprise);
    formData.append("quatier", quatier);
    formData.append("phoneNumber", phoneNumber);
    formData.append("email", email);
    formData.append("password", password);
    myFiles.forEach((file) => formData.append("images[]", file));

    axios
      .post(`${baseUrl}/recruteur/new`, formData)
      .then((res) => {
        console.log(res);
        setLoading(false);
        if (res.status === 201) {
          localStorage.setItem("phoneNumber", phoneNumber);
          localStorage.setItem("password", password);
          setSuccess(true);
          setMessage("Votre comptre a été créé avec success");
          setIsOpen(true);
        } else {
          setError(res.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);

        if (error.response.status === 503) {
          setSuccess(true);
          setMessage(
            `le numéro de téléphone est déja Utilisé veuillez vous connecter et finalisez votre inscription`
          );
          setIsOpen(true);
        } else {
          setError(error.response.data.errors);
          setMessage(error.response.data.errors);
          setIsOpen(true);
        }
      });
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 12,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: 3,
            borderRadius: 2,
            px: 4,
            py: 6,
          }}
        >
          <h1 className="font-medium text-lg">Creation du compte recruteur</h1>

          {errorMessage !== "" ? (
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert severity="error">{errorMessage}</Alert>
            </Stack>
          ) : (
            <span></span>
          )}

          <form onSubmit={handleSubmit} sx={{ mt: 3, mb: 3 }}>
            <Grid container spacing={1}>
              <div className=" border-2 border-blue-600 border-dashed p-2 w-full mt-4">
                <div className="new-bottomLeft">
                  <img src={image} alt="" />
                </div>
                <div className="newFormInput justify-center flex">
                  <label
                    htmlFor="file"
                    className="rounded-xl border-2 border-blue-600  px-4 py-1 my-2"
                  >
                    Ajoutez le logo de votre entreprise{" "}
                    <DriveFolderUploadOutlinedIcon className="icon" />
                  </label>
                  <input
                    type="file"
                    id="file"
                    ref={fileInputRef}
                    onChange={onFileSelect}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Prénom"
                  required
                  name="fisrtName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Nom de l'entreprise "
                  required
                  name="lastName"
                  value={entreprise}
                  onChange={(e) => setEntreprise(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </Grid>

              <div className="w-full md:px-1 lg:flex gap-2">
                <div className="w-full">
                  <select
                    name="HeadlineAct"
                    id="HeadlineAct"
                    className=" w-full rounded-lg border-2 text-gray-700 sm:text-sm p-3"
                    onChange={(e) => setVille(e.target.value)}
                    value={ville}
                  >
                    <option value="">Ville </option>
                    <option value="Douala">Douala</option>
                    <option value="Yaoundé">Yaoundé</option>
                    <option value="Bafoussam">Bafoussam</option>
                    <option value="Buea">Buea</option>
                    <option value="Kumba">Kumba</option>
                    <option value="Limbé">Limbé</option>
                    <option value="Nkongsamba">Nkongsamba</option>
                    <option value="Bafang">Bafang</option>
                    <option value="Mbalmayo">Mbalmayo</option>
                    <option value="Sangmelima">Sangmelima</option>
                    <option value="Bertoua">Bertoua</option>
                    <option value="Bafia">Eseka</option>
                    <option value="Garoua">Garoua</option>
                    <option value="Maroua">Maroua</option>
                    <option value="Bamenda">Bamenda</option>
                    <option value="Edéa">Edéa</option>
                  </select>
                </div>
                <TextField
                  className=" w-full"
                  label="Quatier"
                  value={quatier}
                  onChange={(e) => setQuatier(e.target.value)}
                  required
                />
              </div>

              <Grid item xs={12}>
                <TextField
                  required
                  label="Telephone"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                required
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Mot de passe"
                type={showPassword ? "text" : "password"}
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <div>
              <Dialog open={isOpen}>
                <div
                  role="alert"
                  className="rounded-xl border border-gray-100 bg-white p-4"
                >
                  <div className="flex items-start gap-4">
                    {success ? (
                      <span className="text-green-600">
                        <FaCheck size={35} color="green" />
                      </span>
                    ) : (
                      <span className="text-red-600">
                        <FaClock size={35} color="red" />
                      </span>
                    )}

                    <div className="flex-1">
                      <strong className="block font-bold text-xl">
                        {" "}
                        {success ? "success" : "Alert"}{" "}
                      </strong>

                      <p className="mt-1 text-sm text-gray-700">{message}</p>
                    </div>
                  </div>

                  <div className="flex justify-center mt-4">
                    {loading ? (
                      <div className="flex mx-4 items-center">
                        <CircularProgress />
                      </div>
                    ) : (
                      <button
                        className="px-10 py-2 border bg-blue-600 text-white rounded-xl"
                        onClick={success ? goToLogin : Close}
                      >
                        {success ? "Se connecter" : "fermer"}
                      </button>
                    )}
                  </div>
                </div>
              </Dialog>
            </div>
            {loading ? (
              <i className="fa fa-spinner mt-2 text-danger fa-spin fa-2x"></i>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                S'inscrire
              </Button>
            )}
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/login" variant="body2">
                  Deja un compte ? Connectez vous.
                </Link>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default RegistrationForm;
